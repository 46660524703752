<template>
    <v-container id="locations" ref="locations">
        <h1>Пляжные центры</h1>
        <v-card v-if="!items">
            <h1>Нет элементов</h1>
        </v-card>
        <v-card elevation="15" class="mt-4" v-else>
            <v-tabs v-model="tab"
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows>
                <v-tab
                    color="blue darken-4"
                    v-for="item in items" 
                    :key="item.id">
                    {{item.title}}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item 
                    v-for="item in items"
                    :key="item.id">
                    <v-container fluid class="pa-0">
                        <v-row no-gutters>
                            <v-col cols="12" sm="4">
                                <v-img :src="item.img" :lazy-src="item.img" width="100%" aspect-ratio="1"/>
                            </v-col>
                            <v-col cols="12" sm="8">
                                <v-container class="pt-sm-0 pt-xs-4 mb-4 px-4">
                                    <h2>{{item.content}}</h2>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <h3>Расписание</h3>
                                            <v-card 
                                                elevation="0"
                                                tile
                                                class="mt-1"
                                                v-html="item.schedule">
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <h3 class="mt-2">Адрес</h3>
                                            <v-card 
                                                elevation="0"
                                                tile
                                                class="mt-1"
                                                v-html="item.adress">
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <div class="d-flex mt-4">
                                        <v-btn color="yellow darken-1" :href="item.link" target="_blank">Маршрут</v-btn>
                                        <v-btn class="ml-2 white--text" color="blue darken-4" @click="toggleFormModal" :disabled='formModal'>Записаться</v-btn>
                                    </div>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                    
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    methods:mapActions(['toggleFormModal']),
    computed:mapGetters(['formModal']),
    data(){
        return{
            tab: null,
            items:[
                /*{
                    id: 4, title: "Global Sport", content: "Пляжный центр «Global Sport Arena»", 
                    img: require("@/assets/locations/pav.jpg"), 
                    schedule: "🔹 Вторник: <br> 19:00 - начальный уровень<br> 21:00 - начальный уровень<br><br>🔸 Четверг: <br> 17:00 - детская группа от 5-ти лет<br> 19:00 - начальный уровень<br> 21:00 - начальный уровень<br><br>",
                    adress: "м. Павелецкая/Тульская<br>Москва, Дубининская ул., 69",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.714926%2C37.634604&rtt=taxi"},*/
                {
                    id: 7, title: "Global Sport", content: "Пляжный центр «Global Sport Arena»", 
                    img: require("@/assets/locations/gs.jpg"), 
                    schedule: "🔹 Вторник: <br> 19:00 - начальный уровень<br> 21:00 - начальный+<br><br>🔸 Четверг: <br> 19:00 - начальный уровень<br> 21:00 - начальный+<br><br>",
                    adress: "метро Калужская, ул. Обручева, 34/63 стр1",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.654330%2C37.539410&rtt=taxi"},
                {
                    id: 1, title: "Leto", content: "Пляжный центр «Лето»", 
                    img: require("@/assets/locations/leto-min.jpg"),
                    schedule: "🔹 Среда: <br> 18:45 - начальный+<br> 21:15 - средний уровень<br><br>🔸 Воскресенье: <br> 17:00 - начальный уровень<br> 18:45 - начальный+<br> 20:15 - средний уровень<br><br>",
                    adress: "м. Полежаевская<br>Москва, проспект Маршала Жукова, д. 4, стр. 2",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.777413%2C37.500980&rtt=taxi"},
                {
                    id: 2, title: "Panda Volley", content: "Пляжный центр «Panda Volley Hall»", 
                    img: require("@/assets/locations/panda-min.jpg"),
                    schedule: "🔹 Понедельник: <br> 09:30 - начальный+<br><br>🔸 Среда: <br> 09:30 - начальный+<br><br>",
                    adress: "м. Ленинский проспект<br>Москва, 5-й донской проезд, 15 стр. 7",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.705417%2C37.600415&rtt=taxi"},
                /*{   
                    id: 3, title: "Match Point", content: "Пляжный центр «Match Point Перово»", 
                    img: require("@/assets/locations/perovo_2.jpg"), 
                    schedule: "Ср: 21:00<br>Чт: 19:00",
                    adress: "м. Перово<br>Москва, 1 пр-д Перова Поля, 9 стр. 7",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.750266%2C37.772693&rtt=taxi"},*/
                /*{
                    id: 5, title: "Воробьевы горы", content: "Центр пляжных видов спорта на Воробьёвых горах", 
                    img: require("@/assets/locations/vor.jpg"), 
                    schedule: "⭕️ Вторник: <br> 🔸 17:00 - начальный уровень (подростковая группа)<br> 🔹 19:00 - начальный уровень<br> 🔸 21:00 - начальный+ уровень<br><br>⭕️ Среда:<br> 🔹 18:00 - средний уровень<br> 🔸 20:00 - средний уровень<br><br>⭕️ Четверг: <br> 🔸 17:00 - начальный уровень (подростковая группа)<br> 🔹 19:00 - начальный уровень<br> 🔸 21:00 - начальный+ уровень<br><br>⭕️ Воскресенье: <br> 🔸 15:00 - начальный уровень (подростковая группа)<br> 🔹 17:00 - начальный уровень<br> 🔸 19:00 - средний уровень",
                    adress: "метро Воробьёвы горы <br> Москва, Воробьевская набережная 28 стр. 3",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.712137%2C37.545168&rtt=taxi"}*/
                /*{
                    id: 6, title: "Парк Горького", content: "Спортивный центр в Центральном парке Культуры им. М. Горького ", 
                    img: require("@/assets/locations/park.jpg"), 
                    schedule: "Вторник: <br> 20:00 - начальный уровень<br><br>Среда:<br> 20:00 - средний уровень<br><br>Четверг:<br> 20:00 - начальный +<br><br>Воскресенье:<br> 16:00 - начальный уровень<br> 18:00 - средний уровень",
                    adress: "метро Парк культуры/Октябрьская <br> Москва, ул. Крымский вал 9Г",
                    link: "https://yandex.ru/maps/?ll=37.657228%2C55.748325&mode=routes&rtext=~55.729285%2C37.598573&rtt=taxi"},*/
                
            ]
        }
    }
}
</script>