<template >
    <v-container >
        <h1>Расписание</h1>
        <v-card elevation="15" class="mt-4 pb-2">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                        <tr class="table-head">
                        <th class="text-left table-head-cell">
                            <h2>День</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Global Sport</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>ПЦ "Лето"</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Panda Volley</h2>
                        </th>
                        <!--<th class="text-left table-head-cell">
                            <h2>Match Point</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Парк Горького</h2>
                        </th>-->
                        <!--<th class="text-left table-head-cell">
                            <h2></h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2>Воробьевы горы</h2>
                        </th>
                        <th class="text-left table-head-cell">
                            <h2></h2>
                        </th>-->
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                        v-for="item in schedule"
                        :key="item.name"
                        >
                        <td>{{ item.name }}</td>
                        <td v-html="item.global"></td>
                        <td v-html="item.leto"></td>
                        <td v-html="item.panda"></td>
                        <!--<td>{{ item.match }}</td>
                        <td>{{ item.park }}</td>
                        <td>{{ item.vor }}</td>-->
                        <!--<td class="text-center">{{ item.vor1 }}</td>
                        <td class="text-center">{{ item.vor2 }}</td>
                        <td class="text-center">{{ item.vor3 }}</td>-->
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data(){
        return{
            schedule:[
                {name: "Понедельник", leto: "", panda: "09:30 (начальный+)", match: "", global: "", park: "", vor: "", vor1: "", vor2: "", vor3: ""},
                {name: "Вторник", leto: "", panda: "", match: "", global: "19:00 (начальный уровень) <br>21:00 (начальный+)", park: "", vor: "", vor1: "17:00 (подростковая)", vor2: "19:00 (начальная)", vor3: "21:00 (начальный+)"},
                {name: "Среда", leto: "18:45 (начальный+) <br>20:15 (средний уровень)", panda: "09:30 (начальный+)", match: "", global: "", park: "", vor: "", vor1: "", vor2: "18:00 (средняя)", vor3: "20:00 (средняя)"},
                {name: "Четверг", leto: "", panda: "", match: "", global: "19:00 (начальный урочень) <br>21:00 (начальный+)", park: "", vor: "", vor1: "17:00 (подростковая)", vor2: "19:00 (начальная)", vor3: "21:00 (начальный+)"},
                {name: "Пятница", leto: "", panda: "", match: "", global: "", park: "", vor: "", vor1: "", vor2: "", vor3: ""},
                {name: "Суббота", leto: "", panda: "", match: "", global: "", park: "", vor: "", vor1: "", vor2: "", vor3: ""},
                {name: "Воскресенье", leto: "17:00 (начальный уровень) <br>18:45 (начальный+) <br>20:15 (средний уровень)", panda: "", match: "", global: "", park: "", vor: "", vor1: "15:00 (подростковая)", vor2: "17:00 (начальная)", vor3: "19:00 (средняя)"},
            ]
        }
    }
}
</script>
<style scoped>
.table-head{
    background: #0d47a0;
}
.table-head-cell{
    color: white !important;
}
</style>