<template>
    <v-container id="coaches" ref="coaches">
        <h1>Наши тренеры</h1>
        <v-row class="mt-4">
            <v-col cols="12" sm="6">
                <v-card
                    elevation="15" class="pb-4">
                    <v-img
                        class="white--text align-end"
                        src="@/assets/coach_ks.jpg"
                        lazy-src="@/assets/coach_ks.jpg">
                        <v-card-title style="background: rgba(0,0,0,.5) !important;">Ксения Дячук</v-card-title>
                    </v-img>
                    <v-card-subtitle class="pb-0">
                        главный тренер 
                        </v-card-subtitle>

                        <v-card-text class="text--primary">
                            <v-list dense >
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Высшее образование</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">по специальности Физическая культура, специализация – волейбол / пляжный волейбол</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Победы на турнирах</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">Многократная Чемпионка Москвы по пляжному волейболу. Чемпионка первенства России в 2012году. Серебряный призер чемпионата России 2013 года</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Опытный тренер</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">Работает тренером со взрослыми и детьми с 2014 года. В воспитанницах чемпионка Европы по классическому волейболу> Провела уже более 8тыс. тренировок!</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                        <v-card-actions class="justify-end mr-2">
                            <v-btn color="yellow darken-1" class="black--text" @click="schedule">
                                Расписание
                            </v-btn>
                            <v-btn color="blue darken-4" class="white--text" @click="toggleFormModal" :disabled="formModal">
                                Записаться
                            </v-btn>
                        </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" sm="6">
                <v-card
                    elevation="15" class="pb-4">
                    <v-img
                        class="white--text align-end"
                        src="@/assets/coach_p.jpg"
                        lazy-src="@/assets/coach_p.jpg">
                        <v-card-title style="background: rgba(0,0,0,.5) !important;">Пётр Бахнарь</v-card-title>
                    </v-img>
                    <v-card-subtitle class="pb-0">
                        старший тренер
                        </v-card-subtitle>

                        <v-card-text class="text--primary">
                            <v-list dense >
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Высшее образование</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">Дипломированный тренер высшее образование по специальности «специалист по физической культуре, тренер по волейболу»</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Член сборной России</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">Действующий член сборной России по пляжному волейболу  Профессиональный игрок в пляжный волейбол! Профессионально занимаюсь волейболом/пляжным волейболом с 10 лет</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="px-0">
                                    <v-list-item-icon class="mr-2">
                                        <v-icon color="blue darken-4">mdi-check</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title><h3>Поделится лайфхаками</h3></v-list-item-title>
                                        <v-list-item-subtitle class="wrap-text">Имею большой опыт подготовки и выступления на самых престижных мировых турах! Готов делиться с новичками и теми, кто уже знаком с пляжным волейболом своими знаниями!</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card-text>

                        <v-card-actions class="justify-end mr-2">
                            <v-btn color="yellow darken-1" class="black--text" @click="schedule">
                                Расписание
                            </v-btn>
                            <v-btn color="blue darken-4" class="white--text" @click="toggleFormModal" :disabled="formModal">
                                Записаться
                            </v-btn>
                        </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import goTo from 'vuetify/es5/services/goto'
export default {
    methods:{
        ...mapActions(['toggleFormModal']),
        schedule(){
            goTo(this.getOffset(document.getElementById('schedule')).top - 56);
        },
        getOffset( el ) {
            var _x = 0;
            var _y = 0;
            while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
                _x += el.offsetLeft - el.scrollLeft;
                _y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }
            return { top: _y, left: _x };
        }
    },
    computed:mapGetters(['formModal']),
}
</script>

<style scoped>
.v-responsive__content{
    background: rgba(0,0,0,.5) !important;
}
.wrap-text {
  -webkit-line-clamp: unset !important;
  white-space: normal;
}
</style>